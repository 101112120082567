import { FC, useState, useContext } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import i18next from "i18next";
import { PaymentContext } from "../../../../provider/Payment";
import { loginUserEmailPassword } from "../../../../services/firebase/auth/auth";
import { Button } from "../../../core/Button";
import { Col, Container, Row } from "../../../core/Container";
import {
  assertNotEmpty,
  isObjEmpty,
  TEXT_ERRORS,
  validateEmail,
} from "../../../../utils/validators";
import { Spinner } from "../../../core/Loading";
import { GTMEvents } from "../../../../utils/constants/googleTagManager";
import { mixpanelSignin } from "../../../../utils/tracker";

const CheckoutSignin: FC<{
  onSignupPressed: () => any;
  onSuccess?: () => any;
}> = ({ onSignupPressed, onSuccess }) => {
  const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);
  const [isHidden, setIsHidden] = useState<boolean>(true);
  const [errorForm, setError] = useState<string | null>();
  const { t } = useTranslation();
  // const { setAcceptMarkering } = useContext(PaymentContext);
  
  const validate = (values: any) => {
    const errors: any = {};
    const isFr = i18next.language == "fr";

    if (!values.email) {
      errors.email = t("required", {
        item: isFr ? "L’email est" : "The email is",
      });
    } else {
      if (!validateEmail(values.email)) {
        errors.email = t("invalid", {
          item: isFr ? "une adresse email" : "email",
        });
      }
    }
    if (!values.password) {
      errors.password = t("required", {
        item: isFr ? "Mot de passe" : "The password is",
      });
    }
    return errors;
  };

  const onSignIn = async () => {
    setError(null);
    try {
      if (!isObjEmpty(errors)) throw Error(TEXT_ERRORS.REQUIRED);
      assertNotEmpty(values);
      setIsLoginLoading(true);
      const { user } = await loginUserEmailPassword(values);
      // mixpanelSignin(user);
      window.dataLayer.push({
        event: GTMEvents.login,
        user_id: user?.uid,
        email: user?.email || '',
      });
      // setAcceptMarkering(true);
      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      setError(t(error.message));
    } finally {
      setIsLoginLoading(false);
    }
  };
  const { handleChange, handleBlur, values, errors, touched } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    onSubmit: onSignIn,
  });

  const disabledBtn = isLoginLoading;
  return (
    <Container className="">
      {isLoginLoading ? (
        <div className="mt-40">
          <Spinner />
        </div>
      ) : (
        <Col className="p-[8px] sm:p-[20px] first-letter: w-full bg-white rounded-md gap-6">
          <h3 className="font-bold text-3xl">{t("singin")}</h3>
          <Col className="w-full gap-4 aifs">
            <h5 className="font-bold">{t("email")}</h5>
            <input
              name="email"
              type="email"
              placeholder={t("email")}
              className="w-full h-[55px] border-2 border-[#E0DFE2] rounded-xl px-4"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && (
              <span className="text-red-500 text-sm text-center">
                {errors.email}
              </span>
            )}

            <h5 className="font-bold">{t("password")}</h5>
            <Row className="relative w-full items-center justify-center">
              <input
                placeholder={t("password")}
                name="password"
                type={isHidden ? "password" : "text"}
                className="w-full h-[55px] border-2 border-[#E0DFE2] rounded-xl ps-4 pe-14"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <span
                className="absolute z-20 right-6 cursor-pointer"
                onClick={() => setIsHidden((st) => !st)}
              >
                {isHidden ? (
                  <EyeSlashIcon className="w-5 h-auto fill-black opacity-50" />
                ) : (
                  <EyeIcon className="w-5 h-auto fill-black" />
                )}
              </span>
            </Row>
            {touched.password && errors.password && (
              <span className="text-red-500 text-sm text-center">
                {errors.password}
              </span>
            )}

            <Button
              className="w-full h-[50px] rounded-xl text-white green-gradient-button-bg font-medium mt-14"
              onClick={onSignIn}
              disabled={disabledBtn}
            >
              {t("singin")}
            </Button>
            {errorForm && (
              <span className="text-red-500 text-sm self-center">
                {errorForm}
              </span>
            )}
          </Col>
          <h5 className="text-s text-[#5D5C61] font-medium self-center">
            {t("noAcc")}{" "}
            <span
              onClick={onSignupPressed}
              className="underline text-[#71BD22] cursor-pointer"
            >
              {t("signup")}
            </span>
          </h5>
        </Col>
      )}
    </Container>
  );
};

export default CheckoutSignin;
