import { FC, lazy, Suspense, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";

import CheckoutPage from "../pages/app/Checkout/Checkout";
const NotFound = lazy(() => import("../pages/NotFound"));


const Routing: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path=":lang/:slug" element={<CheckoutPage />} />
        <Route path="/:slug" element={<CheckoutPage />} />
      </Routes>
    </BrowserRouter>
  )
};

export default Routing;