import { FC, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import clsx from "clsx";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";
import { Col, Row } from "../../core/Container";
import { Logo } from "../../generic/Logo";
import { auth, signOutUser } from "../../../services/firebase/auth/auth";
import { Spinner } from "../../core/Loading";
import { Button } from "../../core/Button";
import { getCheckoutDetails } from "../../../services/apis/checkout/plans";
import { PlanTypes, PriceTypes, Signature as SignatureType } from "../../../types/plan.types";

import CheckoutSignup from "./Auth/CheckoutSignup";
import CheckoutSignin from "./Auth/CheckoutSignin";
import PayForm from "./PayForm";
import {PaymentProvider} from "../../../provider/Payment";
import LeftColContent from "./LeftCol";
import { NeedsForm } from "./NeedsForm";
import { FormGiveUp } from "./Signature/Form";
import { ButtonStep3 } from "./ButtonStep3";
import { SignatureStep } from "./SignatureStep";
import { PaymentErrors } from "./PaymentErrors";
import { LoaderCol } from "./LoaderCol";
import { Logout } from "./Logout";

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY ?? ""
);

const Checkout: FC = () => {
  const { lang } = useParams();
  const { slug } = useParams();
  const [currentStep, setCurrentStep] = useState<number>();
  const [currentAuth, setCurrentAuth] = useState("signup");
  const formLang = useMemo(() => lang ?? "fr", [lang]);
  
  const [plan, setPlan] = useState<PlanTypes>();
  const [price, setPrice] = useState<PriceTypes | undefined>();

  
  const totalPrice = useMemo(
    () => {
      return price?.amount ?? 0;
    },
    [price?.amount]
  );
  
  useEffect(() => {
    if (plan?.prices && plan?.prices?.[0]?.price) {
      setPrice(plan?.prices?.[0]?.price);
    }
  }, [plan]);

  const options: StripeElementsOptions = useMemo(
    () => ({
      mode: "payment",
      amount: totalPrice,
      currency: price?.currency,
      locale: formLang as any,
      fonts: [
        {
          cssSrc: "https://fonts.googleapis.com/css?family=Poppins:400,500,600",
        },
      ],
      appearance: {
        theme: "flat",
        variables: {
          colorText: "black",
          borderRadius: "12px",
          fontFamily: "Poppins,sans-serif",
          colorTextPlaceholder: "#c5c5c5",
        },
      },
      paymentMethodCreation: "manual",
    }),
    [formLang, price?.currency, totalPrice]
  );

  useEffect(() => {
    if (slug && setPlan)
      getCheckoutDetails(slug).then(({ data }: any) => {
        setPlan(data);
      });
  }, [setPlan, slug]);

  useEffect(() => {
    setTimeout(() => {
      window.document.getElementById('top_payment_process')?.scrollIntoView(true);
    }, 10);
  }, [currentStep, currentAuth]);

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      if (user) setCurrentStep(2);
      else setCurrentStep(1);
    });
  }, []);

  const onSignOut = useCallback(() => {
    signOutUser();
  }, []);

  const changeDefaultPrice = useCallback((price: PriceTypes | undefined) => {
    setPrice(price);
  }, []);

  return plan && price ? (
    <Elements stripe={stripePromise} options={options}>
      <PaymentProvider plan={plan} defaultPrice={price} changeDefaultPrice={changeDefaultPrice}>
        <div id="top_payment_process" className="">
          <div className="w-full h-[60px] bg-white items-center justify-between flex px-10 drop-shadow-md">
            <Logo />
            <Logout currentStep={currentStep} onSignOut={onSignOut} />
          </div>
          {new URLSearchParams(window.location.search).has('fail') && (
            <>
              <div className="w-full h-100px md:h-[60px] bg-red-500 items-center justify-center flex px-10 drop-shadow-md">
                <p className="text-white text-center text-sm">Une erreur est survenue lors de la signature de votre contrat de formation. Veuillez recommencer l’opération.</p>
              </div>
            </>
          )}
          {new URLSearchParams(window.location.search).has('canceled') && (
            <>
              <div className="w-full h-100px md:h-[60px] bg-red-500 items-center justify-center flex px-10 drop-shadow-md">
                <p className="text-white text-center text-sm">Vous avez refusé la signature de votre contrat de formation. Cette étape est obligatoire. Veuillez renouveler l’opération et signer votre contrat pour accéder à votre formation.</p>
              </div>
            </>
          )}
          
          {
            !currentStep || currentStep === 1 || currentStep === 2 ?
            (
              <>
                <div className="w-full min-h-[calc(100vh-60px)] flex lg:flex-row flex-col-reverse justify-end">
                  <div className="xl:flex-[2] lg:flex-1 bg-[#FCFCFC] md:py-10 py-8 items-center flex flex-col">
                    <LeftColContent plan={plan} />
                  </div>
                  <Col
                    className={clsx(
                      { "min-h-[700px] py-10": currentStep === 1 },
                      "xl:flex-[1.2] lg:flex-[0.7] w-full bg-white shadow-lg xl:px-10 px-2"
                    )}
                  >
                    {currentStep == null ? (
                      <Spinner />
                    ) : (
                      <>
                        {currentStep === 1 && (
                          <>
                            <p className="!pb-0 p-[8px] sm:p-[20px] text-[#71BD22] font-semibold text-3xl">&Eacute;tape 1</p>
                          </>
                        )}
                        {currentStep === 1 ? (
                          currentAuth === "signup" ? (
                            <CheckoutSignup
                              onSuccess={() => {
                                setCurrentStep(2);
                              }}
                              onLoginPressed={() => {
                                setCurrentAuth("login");
                              }}
                            />
                          ) : (
                            <CheckoutSignin
                              onSuccess={() => {
                                setCurrentStep(2);
                              }}
                              onSignupPressed={() => {
                                setCurrentAuth("signup");
                              }}
                            />
                          )
                        ) : (
                          <PayForm currentStep={currentStep} setCurrentStep={setCurrentStep} />
                        )}
                      </>
                    )}
                  </Col>
                </div>
              </>
            )
              :
            (
              <>
                <PaymentErrors fullPage setCurrentStep={setCurrentStep}>
                    <>
                    {
                        currentStep === 3 && (
                          <>
                            <div className="w-full items-center justify-center flex flex-col px-2">
                              <div className="p-[8px] sm:p-[20px]">
                                <p className="text-[#71BD22] font-semibold text-2xl md:text-3xl mb-[8px] sm:mb-[20px] mt-10 sm:mt-20">&Eacute;tape 3</p>
                                <h3 className="font-bold text-2xl md:text-3xl mb-6">Questionnaire obligatoire de prise de besoins</h3>
                    
                                <div className="flex flex-col gap-2 relative">
                                  <div
                                    className="w-full rounded-lg p-4"
                                    style={{
                                      border: "2px solid #DEE2E6",
                                    }}
                                  >
                                    <LoaderCol text="Traitement en cours..." />
                                    <NeedsForm />
                                    <FormGiveUp />
                                  </div>
                                </div>
                              </div>
                              <ButtonStep3 currentStep={currentStep} setCurrentStep={setCurrentStep} />
                            </div>
                          </>
                      )
                    }
                    {
                      currentStep === 4 && (
                        <div className="w-full items-center justify-center flex flex-col px-2">
                            <div className="p-[8px] sm:p-[20px]">
                              <p className="text-[#71BD22] font-semibold text-2xl md:text-3xl mb-[8px] mb-[8px] sm:mb-[20px] mt-10 sm:mt-20">&Eacute;tape 4</p>
                              <h3 className="font-bold text-2xl md:text-3xl">Signature électronique de votre contrat de formation</h3>
                              <h4 className="font-normal text-2xl md:text-3xl mb-6">(dernière étape, obligatoire pour finaliser votre achat !)</h4>
                  
                              <div className="flex flex-col gap-2 relative">
                                 <SignatureStep currentStep={currentStep} setCurrentStep={setCurrentStep} />
                              </div>
                            </div>
                        </div>
                      )
                    }
                    </>
                </PaymentErrors>
              </>
            )
          }
        </div>
      </PaymentProvider>
    </Elements>
  ) : <div className="flex items-center justify-center w-screen min-h-screen"><Spinner /></div>;
};

export default Checkout;
